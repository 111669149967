$micro: 320px;
$small: 480px;
$medium: 667px;
$vtab: 768px;
$htab: 1024px;
$large: 1366px;

.headers {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
}

::-webkit-scrollbar {
  width: 15px;
}

th {
  text-wrap: nowrap;
}

.bounceListData td {
  text-wrap: nowrap;
}

::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  /* background-color: #5285fa; */
  background-image: linear-gradient(180deg, #f4a9d2 0%, #a7baed 99%);

  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.headerH1 {
  /* margin-left: 400px; */
  font-size: 38px;
  color: #f00000;
  /* margin-top: 20px; */
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

.headerH4 {
  /* margin-left: -850px;
  margin-top: 65px; */
  font-weight: 400;
  font-size: 25px;
  color: rgb(0, 153, 255);
}

.buttons {
  margin-right: 150px;
  margin-top: 35px;
}

.list {
  /* margin-top: 150px; */
  border: #00aff0;
  /* margin-left: -200px;
  margin-top: 35px; */
  font-weight: bold;
  background-color: #00aff0;
  color: black;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 15px;
  height: fit-content;
}

.options {
  margin-left: -200px;
  width: 100px;
}

.img {
  margin-top: 10px;
  /* margin-left: 210px; */
}

.carousel-section img
{
  object-fit: cover;
}

.middle {
  background: url(../src/Images/background/bg.png);
  /* padding: 20px 20px 130px 20px; */
}

.home-component 
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #fff;
}

.company-info-container
{
  background-color: #ffffff;
}

.company-info
{
  // background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(./Images/3.jpg) center center no-repeat;
  color: #000;
}


.content
{
  text-align: center;

  @media screen and (max-width: $medium)
  {
    text-align: justify;
  }
  
}


.logo-slider
{
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;
}

.scroller
{
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  position: relative;
  height: 175px;
  mask: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent);

  @media screen and (max-width: $medium)
  {
    width: 90%;
  }
  
  ul.scroller_inner 
  {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    transform: translate(0%);
    animation: scroll 80s linear infinite;
    position: absolute;
    left: 0%;
    padding: 0;
    transition: 1s;
    filter: grayscale(100%);

    &:hover
    {
      filter: grayscale(0%);
    }

    li
    {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;

      img
      {
        width: 100px;
        height: 180px;
        object-fit: contain;
      }
    }
  }
}


@keyframes scroll
{
  to
  {
    left: calc(100% - 7020px);
  }
}


.main {
  -webkit-animation-duration: 2s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  padding: 0px;
  /* background-image: linear-gradient(320deg, pink, white); */
}

.mainLogin {
  -webkit-animation-duration: 2s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  padding: 0;
  display: flex;
  justify-content: center;
  /* background-image: linear-gradient(320deg, pink, white); */
}

/* .mainLogin {
  -webkit-animation-name: main;
  animation-name: main;
} */

@-webkit-keyframes main {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@keyframes main {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.main {
  -webkit-animation-name: main;
  animation-name: main;
}


.inside_f {
  line-height: 190%;
  color: black;
  font-weight: 700;
  margin-left: 50px;
}

.inside_ff {
  float: right;

  margin-right: 350px;
  margin-top: -150px;
}

.inside_ff ul {
  list-style: none;
}

.inside_fff {
  float: right;
  margin-right: 110px;
  margin-top: -150px;
}

.inside_fff ul {
  list-style: none;
}

select {
  color: rgb(116, 115, 115);
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgb(177, 176, 176);
  padding: 9px;
  margin-right: 200px;
  margin-top: -80px;
  padding: 10px;
  border-radius: 5px;
  width: 250px;
}

.ss {
  margin-top: -30px;
}

.customer_header {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  box-shadow: 0px 0px 15px 0px #ccc;
}

/* drop down */

.loginForm {
  /* width: 550px; */
  border: 1px solid rgb(255, 255, 255);
  /* margin-top: 100px; */
  height: 335px;
  /* position: absolute; */
  /* margin-left: 500px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.loginForm input {
  width: 500px;
  border: 0;
  border-bottom: 1px solid rgb(180, 180, 180);
  margin: 20px;
  padding: 8px;
}

.btns {
  width: 100%;
  margin-top: 6px;
  padding: 8px;
  border: yellow;
  font-size: 21px;
  background-color: yellow;
}

.api {
  float: right;
  font-weight: 700;
  /* margin-top: -300px; */
  /* margin-right: 150px; */
}

.overflow {
  /* width: 500px; */
  /* height: "100%"; */
  /* max-height: 100%; */
  padding: 5px;
  overflow: hidden;
  border: 1px solid #ffb6c1;
}

@media screen and (min-width: 1024px) {
  .overflow {
    padding-left: 50px;
  }
}

/* Product List Page  */

.compa:hover {
  background-color: #e9e9e9;
}

.product_header {
  /* margin-top: 20px; */
}

.R {
  float: right;
  width: 150px;
  height: 60px;
  margin-top: -20px;
  margin-right: 20px;
}

#S {
  width: 100%;
  height: auto;
  margin-top: -20px;
  /* margin-left: 20px; */
}

.lists 
{
  position: relative;
  height: 66px;
  display: flex;
  list-style: none;
  width: auto;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  z-index: 0;
}

.lists li {
  padding: 20px 10px;
  font-size: 16px;
  color: black;
}

.lists :hover {
  background-color: #f4ab01;
  transition: 0.4s;
}

.data {
  color: #2ec8a6;
  list-style: none;
  line-height: 1.9;
  padding: 0px 18px 0px 0px;
  font-weight: 400;
}

.P_grids {
  width: 100%;
  overflow-x: scroll;
  margin-left: 200px;
  color: #2ec8a6;
  margin-top: 20px;

  margin-bottom: 50px;
}

.grids {
  margin-bottom: 50px;
}

.comp {
  /* margin-left: 200px; */
  margin-top: 15px;
  font-size: 16.5px;
  color: rgb(125, 125, 125);
  font-weight: 700;
}

/* footer component */

.P_footer {
  width: 100%;
  background-color: #66cccc;
  /* position: -webkit-sticky; */
  position: fixed;

  bottom: 0;
}

.P_footer p {
  text-align: center;
  margin: 0;
  font-size: 16px;
}

.white {
  font-weight: 500;
  color: white;
}

.logM {
  position: absolute;
  margin-top: 450px;
  margin-left: 600px;
}

#dropdown-item {
  max-width: 150px;
}

.Dlist {
  color: black;
  text-align: center;
  padding: 5px 90px 5px 5px;
  text-decoration: none;
  width: 100%;
  transition: 0.2s;
}

.Dlist:hover {
  background-color: #00aff0;
  color: white;
  padding-left: 20px;
  border-radius: 15px;
  outline: none;
}

@media (max-width: 1000px) {
  .logo {
    width: 120px;
    height: 100px;
  }

  .headers h2 {
    width: 500px;
    margin-top: 100px;
    margin-left: 200px;
  }

  .headers h4 {
    margin-top: 150px;
    margin-right: -450px;
  }

  .api {
    /* margin-right: 130px; */
    margin-top: 20px;
  }

  .inside_f {
    width: 500px;
  }

  .inside_f p {
    font-size: 10px;
  }

  .inside_ff {
    margin-left: 40px;
    margin-right: 100px;
    font-size: 10px;
  }

  .inside_fff {
    margin-right: -170px;
    font-size: 10px;
  }

  .compa {
    font-size: 10px;
  }

  .grids {
    /* margin-left: 30px; */
    width: 100%;
    /* border: 2px solid rgb(208, 206, 206); */
    margin-top: 30px;
  }
}
