.searchbar {

    margin-left: 200px;
    margin-top: 50px;
    margin-bottom: 30px;
}

.searchbar input {
    width: 1200px;
    border: 0;
    border: 2px solid#66cccc;
    padding: 10px;

}

.searchbar input:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px 0px;
}

.admin-logo
{
    width: 65px;
}

.providerlogo 
{
    width: 124px;
    height: auto;
}

.product_header
{
    box-shadow: -1px -20px 20px 0px rgba(0,0,0,0.2), 1px -2px 20px 0px rgba(0,0,0,0.19);
}

.productList {
    width: 1200px;
    margin-left: 200px;
    overflow: scroll;
    height: 300px;
    position: relative;
}



.DropC {
    margin-top: 10px;
    /* margin-left: 200px; */
}

.remarks {
    margin-top: 10px;
    /* margin-left: 200px; */

}

.placeOrder {
    text-align: center;
    /* font-family: 'Courier New', Courier, monospace; */
    color: rgb(5, 6, 6);
    margin-top: 20px;
    margin-bottom: -30px;

}

.BtnsA {
    background-color: #5BC0DE;
    border: 1px solid #5BC0DE;
    padding: 10px;
    border-radius: 5px;
    margin-left: 620px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 150px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.IncrPrice {
    border-radius: 50%;
    color: black;
    border: 1px black;
    background-color: aliceblue;
}

.CustomerFooter {

    width: 100%;

    background-color: #66cccc;
    position: -webkit-sticky;
    position: sticky;
    position: fixed;
    top: 680px;


}

.CustomerFooter p {
    text-align: center;

}

.white {
    font-weight: 500;
    color: white;
}

.AddedTable {
    position: relative;
}

.AddedTable1 {
    /* margin-left: 100px; */
    margin-bottom: 100px;
    border: 1px solid black;
    padding: 10px;
    width: 1400px;
    text-align: left;
    background-color: black;
    color: white;
}

.AddedTable1 li {
    text-align: left;
    list-style: none;
    display: inline;
    /* margin-right: 160px;
    margin-left: -20px; */
}

.AddedTable2 {
    border: 1px solid black;
    width: 1400px;
    background-color: #66cccc;
    color: white;
}

.AddedTable2 li {
    list-style: none;
    display: inline-flex;
    padding-right: 0px;

    font-size: 12.5px;
}

.AddedTable {
    width: 1200px;
    margin-left: 190px;
    position: static;
}

/* .carousel {
    margin-left: 390px;
    margin-top: -160px;
    position: absolute;
    z-index: -1;
} */

.SubmitButton {
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    font-weight: 700;
    background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(238, 174, 202, 1) 15%, rgba(148, 187, 233, 1) 100%);
    transition: 0.5s;
}

.orderNo {
    color: rgb(83, 82, 82);
    text-align: center;
    border-bottom: 1px solid rgb(210, 209, 209);
    width: 200px;
    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 25px 20px -11px;
}

.SubmitButton:hover {
    background-color: white;
    color: rgb(112, 110, 110);
}

.cmpName {
    position: absolute;
    text-align: left;
    font-size: 10px;
    color: green;
}





.prodN {
    color: darkblue;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
}



/* dropdown */
.Remarks_list {
    /* position: -webkit-sticky; */
    /* display: inline-block; */
    border: 1px solid rgb(187, 186, 186);
    /* padding: 8px; */
    /* width: 100%; */
    border-radius: 5px;
}

.Remarks_list h6 {
    padding-top: 1px;
    color: rgb(117, 116, 116);
    font-weight: 400;
    font-family: Courier;
}

.selection-remarks-list {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* width: 100%; */
    position: absolute;
    background-color: #ffffff;

    z-index: 1;

}

.selection-remarks-list li {
    z-index: 0;
    list-style: none;
    font-family: Courier;
    padding: 8px;
    cursor: pointer;
    width: 100%;
}

.selection-remarks-list textarea {
    margin-left: 10px;
    padding-bottom: 5px;
}

.dropdowns {
    position: relative;
    display: inline-block;
    border: 1px solid rgb(187, 186, 186);
    padding: 8px;
    width: 550px;
    border-radius: 5px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    transition: 1s;
    height: 213px;
}


.dropdowns:hover .dropdown-content {
    display: block;
    width: 550px;
    margin-left: -9px;
    margin-top: 9px;
    border-top: 3px solid #66cccc;

}

.dropdown-content li {
    list-style: none;
    padding: 16px;
    margin-left: -16px;
    margin-top: -10px;
}

.dropdown-content li:hover {
    background-color: rgb(237, 236, 236);
    width: 550px;
    transition: 0.9s;

}

.dropdwnsName {
    color: rgb(117, 117, 117);
}

textarea {
    border: 1px solid rgb(184, 182, 182);
    border-radius: 5px;
}

textarea[type=text]:focus {
    border: 3px solid blue;
}

#searchbar {
    /* margin-left: 190px; */

    padding: 20px 0px 20px 0px;

}

.ProductSearch {
    border-radius: 5px;
    margin-top: 20px;

    /* padding: 10px; */
}

.ProductSearch h6 {
    font-weight: 700;
    color: #747171;
    font-family: 'Open Sans', sans-serif;
}


.search_products::placeholder {
    font-size: 17px;
}

.menu {
    background-color: rgb(220, 220, 89);
}

.inputQuantity {

    border: 1px solid rgb(157, 155, 155);
    border-radius: 4px;
}

input:focus {
    outline: none !important;
    box-shadow: 0 3px 10px rgba(59, 98, 218, 0.5);
    border: 1px solid rgb(74, 130, 213);
}

.Cust-Order-list {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    list-style: none;
    height: 50px;
    width: auto;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
}

.loggedin-user-info
{
    background-color: #f5f5f5;
}

.Cust-Order-list::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

.Cust-Order-list li {
    text-wrap: nowrap;
    padding: 10px 15px;
    font-size: 16px;
    color: black;
}

.Cust-Order-list :hover {
    background-color: #f4ab01;
    transition: 0.4s;
}


.logOut_customer {
    color: black;
    opacity: 1;
    text-align: center;
    margin-top: 0px;
    margin-left: 0px;
    text-align: left;
    position: absolute;
    transition: 0.2s;
    background-color: white;
    cursor: pointer;
    width: 150px;
    padding-left: 10px;
    box-shadow: 0px 3px 5px -1px #ccc;
}

.logOut_customer :hover {
    border-left: 2px solid #3ca0e7;
    color: #3ca0e7;
    transition: all 0.5s ease;
    margin-left: 20px;
}


.Dreport_list {

    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 0px;
    margin-left: 0px;
    text-align: left;
    position: absolute;
    transition: 0.2s;
    background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(238, 174, 202, 1) 15%, rgba(148, 187, 233, 1) 100%);
    /* background-color: #69d1f7; */
    /* background-color: #086afd66; */
    width: 150px;
    padding-left: 10px;
    /* box-shadow: 0px 3px 5px -1px #353434; */
    z-index: 500;
}

.list_reports {
    margin-top: 5px;

}

.list_reports:hover {
    color: black;
    transition: all 0.7s ease;
    margin-left: 20px;

}

.compamy_list h6 {
    font-family: 'Open Sans', sans-serif;
    /* margin-left: 200px; */
    font-weight: 700;
    color: #747171;
}

.select-company h6 {
    font-family: 'Open Sans', sans-serif;
    margin-left: 200px;
    font-weight: 700;
    color: #747171;
}

.select-company select {
    width: 1100px;
    margin-left: 200px;
}



.CustomerSubmitSection {
    /* position: static; */
}

.InpDate {
    margin-left: 10px;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid rgb(206, 201, 201);
    box-shadow: rgba(172, 172, 175, 0.25) 0px 30px 60px -12px inset, rgba(231, 229, 229, 0.3) 0px 18px 36px -18px inset;
}


.dateRangePicker {
    text-align: center;
}

.dateRangePicker_1 {
    text-align: center;
    position: static;
}

.Ledger h5 {
    font-weight: 600;
    font-size: 15px;
    margin-top: 30px;
    margin-left: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.BtnsB {
    background-color: #5BC0DE;
    border: 1px solid #5BC0DE;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 70px;
    color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.Ledger_Data button {
    float: right;
    margin-top: -40px;
    margin-bottom: 20px;
    width: 100px;
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    border-radius: 5px;
    color: white;
    padding: 5px;
}

.enterDateInput input {
    border: 1px solid rgb(125, 122, 122);
    color: rgb(92, 91, 91);
    padding: 5px;
    border-radius: 5px;
}

.enterDateInput button {
    width: 60px;
    background-color: #3ca0e7;
    color: #ffffff;
    border: 1px solid #3ca0e7;
    border-radius: 5px;

}




.Ledger_Data {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.threAd {
    background-color: #5BC0DE;
    color: white;
    /* border: 1px solid rgb(159, 157, 157); */

}

.threAd_tr th {
    border: 1px solid rgb(159, 157, 157);

}

.tboDY td {
    border: 1px solid rgb(159, 157, 157);

}

.pendingBill h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.pendingBill button {

    width: 100px;
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    border-radius: 5px;
    color: white;

}

.pendingBill {
    padding: 10px;
}

.pendingBillData {
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.bounceList_1 {
    padding: 10px;
}

.bounceList_1Button {
    float: right;
    width: 100px;
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    border-radius: 5px;
    color: white;
    padding: 5px;
}

.bounceList_1Span {
    float: right;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
}

.bounceList_1 input {
    border-radius: 5px;
    border: 1px solid rgb(121, 120, 120);
}

.bounceList_1H3 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 100;
    font-size: 19px;
}

.bounceList_1H5 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 100;
    font-size: 13px;
    padding-bottom: 5px;
}

.bounceListData {
    /* margin-top: -40px; */
    margin-bottom: 40px;
}





.billtrack {
    margin-top: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.billTarckData button {
    float: right;
    margin-top: -40px;
    margin-bottom: 20px;
    width: 100px;
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    border-radius: 5px;
    color: white;
    padding: 5px;
}

.billtrack h4 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}

.billtrack button {
    width: 100px;
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    border-radius: 5px;
    color: white;

}

.billTarckData {
    padding: 10px;
}




.BTNs {
    text-align: center;
}

.csvBTN {
    width: 100px;
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    border-radius: 5px;
    color: white;
    padding: 5px;
    text-align: center;
}

.pdfBTN {
    width: 100px;
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    border-radius: 5px;
    color: white;
    padding: 5px;
    margin-left: 10px;
    text-align: center;
}

.exclBTN {
    width: 100px;
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
    border-radius: 5px;
    color: white;
    padding: 5px;
    margin-left: 10px;
    text-align: center;

}






@media(max-width:1000px) {
    .loginForm {
        margin-left: 40px;
        width: 800px;
    }

    .loginForm input {
        width: 750px;
    }




    .CustomerSubmitSection {
        /* margin-left: -100px; */

    }

    .AddedTable {

        width: 800px;
    }

    .BtnsA {
        margin-left: 300px;
    }


    .dropdowns {
        width: 400px;
    }





}